// Generated by Framer (e8e43ad)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/IOuqnH7Q_";
import * as sharedStyle2 from "../css/lrjqL1gD_";
import * as sharedStyle1 from "../css/PVFiLNmt0";
import * as localizedValues from "./BbtqQ8BTV-0.js";

const enabledGestures = {haSQ7hQ1K: {hover: true}};

const cycleOrder = ["haSQ7hQ1K", "JeT520d0E"];

const serializationHash = "framer-xM5r3"

const variantClassNames = {haSQ7hQ1K: "framer-v-66k9z9", JeT520d0E: "framer-v-iqnpqz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {bCVeFfDXK: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Clicked: "JeT520d0E", Unclicked: "haSQ7hQ1K"}

const getProps = ({filter, height, id, tap, width, ...props}) => { return {...props, FxX2HTGLl: tap ?? props.FxX2HTGLl, sNdNfUWR9: filter ?? props.sNdNfUWR9 ?? "Filter", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "haSQ7hQ1K"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;filter?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sNdNfUWR9, FxX2HTGLl, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "haSQ7hQ1K", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapejhyoq = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (FxX2HTGLl) {
const res = await FxX2HTGLl(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className, sharedStyle2.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-66k9z9", className, classNames)} data-framer-name={"Unclicked"} data-highlight layoutDependency={layoutDependency} layoutId={"haSQ7hQ1K"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapejhyoq} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"haSQ7hQ1K-hover": {"data-framer-name": undefined}, JeT520d0E: {"data-framer-name": "Clicked"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1ieyypu"} data-styles-preset={"IOuqnH7Q_"}>Filter</motion.p></React.Fragment>} className={"framer-1g0xbob"} data-framer-name={"Filter"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"k8UknioaK"} style={{"--framer-paragraph-spacing": "0px"}} text={sNdNfUWR9} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"haSQ7hQ1K-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-13ppv7i"} data-styles-preset={"lrjqL1gD_"}>Filter</motion.p></React.Fragment>}, JeT520d0E: {children: <React.Fragment><motion.p className={"framer-styles-preset-s1t297"} data-styles-preset={"PVFiLNmt0"}>Filter</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xM5r3.framer-6op5vz, .framer-xM5r3 .framer-6op5vz { display: block; }", ".framer-xM5r3.framer-66k9z9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-xM5r3 .framer-1g0xbob { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xM5r3.framer-66k9z9 { gap: 0px; } .framer-xM5r3.framer-66k9z9 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-xM5r3.framer-66k9z9 > :first-child { margin-left: 0px; } .framer-xM5r3.framer-66k9z9 > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css, ...sharedStyle2.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 42
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"JeT520d0E":{"layout":["auto","auto"]},"WwNA6ycRf":{"layout":["auto","auto"]}}}
 * @framerVariables {"sNdNfUWR9":"filter","FxX2HTGLl":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBbtqQ8BTV: React.ComponentType<Props> = withCSS(Component, css, "framer-xM5r3") as typeof Component;
export default FramerBbtqQ8BTV;

FramerBbtqQ8BTV.displayName = "Filter";

FramerBbtqQ8BTV.defaultProps = {height: 25, width: 42};

addPropertyControls(FramerBbtqQ8BTV, {variant: {options: ["haSQ7hQ1K", "JeT520d0E"], optionTitles: ["Unclicked", "Clicked"], title: "Variant", type: ControlType.Enum}, sNdNfUWR9: {defaultValue: "Filter", displayTextArea: false, title: "Filter", type: ControlType.String}, FxX2HTGLl: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerBbtqQ8BTV, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts), ...getFontsFromSharedStyle(sharedStyle2.fonts)], {supportsExplicitInterCodegen: true})